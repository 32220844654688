.juv-features-carousel {
  &.emu-carousel.emu-carousel {
    padding-bottom: var(--emu-common-spacing-none);

    @include mq('large') {
      padding-top: var(--emu-common-spacing-none);
    }
  }

  .emu-carousel__item {
    &:last-child {
      .emu-carousel__item-container {
        border-right: none;
      }
    }

    &-container {
      border-right: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);
      border-top: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);
    }
  }

  .brand-card {
    border-top: none;

    @include mq('large') {
      width: 100%;
    }

    &__content {
      @include mq('large') {
        padding-top: 52px;
      }
    }

    &__title {
      margin-bottom: var(--emu-common-spacing-small);
    }
  }
}
