.teaser {
  .emu-teaser {
    &.emu-teaser-v3.inline-text-overlay {
      .teaser-container {
        .content-container {
          padding-top: 45px;
          padding-bottom: var(--emu-common-spacing-medium);

          @include mq('x-large') {
            padding-top: 62px;
            padding-bottom: 108px;
          }

          .emu-teaser__title {
            margin-bottom: 33px;

            @include mq('large') {
              font-size: var(--emu-common-font-sizes-display-regular);
              line-height: var(--emu-common-line-heights-custom-xs);
              letter-spacing: -1.2px;
            }
          }

          .emu-teaser__description {
            p {
              font-family: var(--emu-common-font-families-serif-bold);
              font-size: var(--emu-common-font-sizes-headline-medium);
              line-height: var(--emu-common-line-heights-narrow-large);
              letter-spacing: -0.48px;
            }
          }

          .emu-teaser__disclaimer {
            margin-top: 30px;

            @include mq('x-large') {
              position: absolute;
              width: 100%;
              margin-top: var(--emu-common-spacing-none);
              padding-top: var(--emu-common-spacing-brand-xs);
              padding-bottom: 20px;
              padding-left: var(--emu-common-spacing-brand-medium);
              padding-right: var(--emu-common-spacing-brand-medium);
              border-top: var(--emu-common-border-width-thin) solid
                var(--emu-common-colors-brand-black-300);
            }

            p {
              @include mq('large') {
                font-size: var(--emu-common-font-sizes-captions-large);
              }
            }
          }

          sup {
            font-size: 60%; // to overwrite default sup font size for teaser
          }
        }
      }
    }
  }
}
