#resources {
  .resources {
    &__teaser {
      .teaser-container {
        .content-container {
          @include mq('x-large', 'max-width') {
            padding-top: 39px;
            padding-bottom: var(--emu-common-spacing-brand-xs);
          }

          @include mq('x-large') {
            padding-bottom: 45px;
          }
        }

        .emu-teaser__image img {
          @include mq('x-large', 'max-width') {
            max-height: 573px; // to match figma
            object-position: top;
          }
        }
      }
    }

    &__aesthetics {
      padding-top: 37px;

      @include mq('large', 'max-width') {
        padding-bottom: var(--emu-common-spacing-brand-medium);
      }

      @include mq('large') {
        padding-top: 70px;
      }

      .intro-box {
        &__title {
          margin-bottom: var(--emu-common-spacing-medium);

          @include mq('large') {
            max-width: 1150px; // to match design
            margin-bottom: 10px;
          }

          .emu-title__text {
            letter-spacing: -0.2px;

            @include mq('large') {
              letter-spacing: -1.2px;
            }
          }
        }
      }
    }

    &__customers {
      &.intro-box {
        border-bottom: var(--emu-semantic-border-width-thin) solid
          var(--emu-common-colors-black);
        padding-top: 40px;
        padding-bottom: 24px;

        @include mq('large') {
          padding-top: 60px;
          padding-bottom: 79px;
        }

        .intro-box__title {
          @include mq('large') {
            letter-spacing: -1.2px;
          }
        }
      }
    }

    &__order,
    &__get-training,
    &__find-patients,
    &__drive-engagement {
      .img-txt-grid {
        &__section {
          &--content {
            padding-top: 37px;
            padding-right: var(--emu-common-spacing-medium);
            padding-bottom: 51px;

            @include mq('large') {
              padding-top: 65px;
              padding-right: var(--emu-common-spacing-brand-medium);
              padding-bottom: var(--emu-common-spacing-brand-medium);
            }
          }
        }

        &__title {
          margin-bottom: 18px;
          padding-right: var(--emu-common-spacing-none);

          @include mq('large') {
            margin-bottom: 16px;
          }

          .cmp-title__text {
            letter-spacing: -0.2px;

            @include mq('large') {
              letter-spacing: -1px;
            }
          }
        }

        &__text {
          margin-bottom: var(--emu-common-spacing-brand-xs);
        }

        &__cta {
          margin-bottom: var(--emu-common-spacing-large);
        }
      }
    }

    &__get-training,
    &__find-patients {
      .img-txt-grid {
        &__section--content {
          @include mq('large', 'max-width') {
            padding-top: 38px;
            padding-bottom: var(--emu-common-spacing-large);
          }
        }
      }
    }

    &__contact-details {
      .intro-box {
        padding-top: 38px;
        padding-bottom: 25px;

        @include mq('large') {
          padding-top: 62px;
          padding-bottom: 85px;
        }

        &__title {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      .brand-card {
        .emu-title__text {
          line-height: var(--emu-common-line-heights-custom-regular);
          margin-bottom: 23px;
        }

        &__content {
          @include mq('large', 'max-width') {
            padding-top: 37px;
            min-height: 160px; // to match design
          }

          @include mq('large') {
            padding-top: 46px;
          }
        }

        &__title {
          padding-top: var(--emu-common-spacing-none);

          @include mq('large') {
            padding-top: 14px;
            margin-bottom: var(--emu-common-spacing-none);
          }

          .cmp-title__text {
            letter-spacing: -0.1px;
            margin-bottom: 18px;
          }
        }

        &__txt {
          @include mq('large', 'max-width') {
            margin-bottom: var(--emu-common-spacing-none);
          }

          p + p {
            margin-top: var(--emu-common-spacing-brand-xs);
            letter-spacing: 0.3px;
          }
        }
      }
    }

    &__report-teaser {
      @include mq('x-large') {
        margin-bottom: var(
          --emu-common-spacing-xxs
        ); // to match ISI starting position
      }

      .teaser-container {
        .emu-teaser {
          &__image img {
            @include mq('x-large', 'max-width') {
              padding-left: 10px; // to match the design
              margin-bottom: 3px; // to match the design
            }
          }
        }

        .content-container {
          @include mq('large', 'max-width') {
            padding-bottom: 14px;
          }

          .title-lockup {
            max-width: 745px; // to match design
          }

          .emu-teaser {
            &__title {
              margin-bottom: 18px;

              @include mq('x-large') {
                margin-bottom: var(--emu-common-spacing-brand-xs);
                letter-spacing: -1px;
              }
            }

            &__subtext {
              @include mq('large', 'max-width') {
                margin-top: 22px;
              }
            }
          }
        }
      }
    }
  }
}
