#values {
  .values {
    &__delivers-teaser {
      .teaser-container {
        .content-container {
          @include mq('large') {
            padding-top: 68px;

            .title-lockup {
              max-width: 771px; // As per figma design
            }
          }

          .emu-teaser {
            &__title {
              @include mq('large', 'max-width') {
                margin-bottom: 34px;
              }

              @include mq('large') {
                letter-spacing: -1px;
              }
            }

            &__disclaimer {
              margin-top: var(--emu-common-spacing-none);

              @include mq('large') {
                padding-bottom: 22px;
              }
            }
          }
        }
      }
    }

    &__providers {
      .img-txt-grid {
        &__section {
          &--content {
            @include mq('large', 'max-width') {
              padding-top: 36px;
              padding-bottom: var(--emu-common-spacing-none);
            }

            @include mq('large') {
              padding-top: 67px;
            }
          }
        }

        &__title {
          margin-bottom: 19px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-medium);
          }

          .emu-title__text {
            letter-spacing: 0px;

            @include mq('large') {
              letter-spacing: -0.5px;
            }
          }
        }

        &__text {
          p {
            letter-spacing: -0.1px;
          }
        }

        &__img img {
          height: 600px; // as per figma
        }
      }
    }

    &__filler-teaser {
      .teaser-container {
        .content-container {
          .emu-teaser {
            &__title {
              margin-bottom: 25px;
              letter-spacing: -0.2px;

              @include mq('large') {
                letter-spacing: -1.2px;
                margin-bottom: -3px;
              }
            }

            &__subtext {
              padding-top: var(--emu-common-spacing-none);

              p {
                font-size: var(--emu-common-font-sizes-narrow-medium);
                letter-spacing: -0.5px;
              }
            }

            &__disclaimer {
              @include mq('large') {
                padding-bottom: 22px;
              }
            }
          }
        }
      }

      .emu-teaser__image img {
        @include mq('large') {
          min-height: 425px; // to match design
        }
      }
    }

    &__patients {
      &.three-card-grid {
        > .container:first-child {
          .brand-card__content {
            @include mq('medium', 'max-width') {
              border-top: none;
            }
          }
        }

        .brand-card {
          &__content {
            @include mq('large', 'max-width') {
              padding-right: 19px;
              padding-top: 33px;
            }

            @include mq('large') {
              padding-top: 62px;
              padding-bottom: 54px;
              border-top: none;
            }
          }

          &__title {
            margin-bottom: 15px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-medium);
            }

            .emu-title__text {
              @include mq('large', 'max-width') {
                letter-spacing: -1px;
              }
            }
          }

          &__subtitle {
            .cmp-title__text {
              @include mq('large', 'max-width') {
                line-height: 108%;
              }

              b {
                @include mq('large') {
                  display: inline-block;
                }
              }
            }

            &--spacing {
              .cmp-title__text {
                @include mq('large', 'max-width') {
                  letter-spacing: -0.2px;
                }
              }
            }
          }
        }
      }
    }

    &__long-last-teaser {
      .teaser-container {
        .content-container {
          @include mq('large', 'max-width') {
            padding-top: 46px;
          }

          .emu-teaser {
            &__title {
              @include mq('large', 'max-width') {
                margin-bottom: 6px;
                letter-spacing: -0.2px;
              }

              @include mq('large') {
                letter-spacing: -1px;
              }
            }

            &__disclaimer {
              margin-top: 28px;

              @include mq('large') {
                display: none;
              }
            }
          }
        }
      }

      .emu-teaser__image img {
        @include mq('large') {
          min-height: 425px; // to match design
        }
      }
    }

    &__survey {
      &.three-card-grid {
        > .container {
          flex-grow: 1;

          &:first-child {
            .brand-card {
              &__content {
                @include mq('medium', 'max-width') {
                  border-top: none;
                }
              }
            }
          }

          &:last-child {
            border-right: none;
          }
        }

        .brand-card {
          &__content {
            padding-top: 33px;

            @include mq('large') {
              padding-top: 63px;
              padding-bottom: 54px;
              border-top: none;
            }
          }

          &__title {
            margin-bottom: 15px;
          }

          &__subtitle {
            @include mq('large') {
              letter-spacing: -0.2px;
            }
          }
        }
      }
    }

    &__business {
      @include mq('large', 'max-width') {
        padding-top: 38px;
        padding-bottom: var(--emu-common-spacing-brand-medium);
      }

      @include mq('large') {
        padding-top: 65px;
      }

      .intro-box {
        &__title {
          margin-bottom: 17px;

          @include mq('large') {
            max-width: 1062px; // to match design
          }

          .emu-title__text {
            letter-spacing: -0.2px;

            @include mq('large') {
              letter-spacing: -0.7px;
            }
          }
        }

        &__cta {
          .cmp-button__text {
            letter-spacing: -0.4px;
          }
        }
      }
    }
  }

  .pre-footer {
    .intro-box {
      padding-bottom: 23px;

      @include mq('large') {
        padding-bottom: 57px;
      }
    }

    .img-txt-grid {
      &--join-alle {
        @include mq('large', 'max-width') {
          padding-bottom: var(--emu-common-spacing-xxs);
        }
      }

      &__section {
        &--content {
          padding-bottom: 59px;
        }
      }

      &__title {
        .emu-title__text {
          @include mq('large') {
            max-width: 74%;
          }
        }
      }

      &__cta {
        @include mq('large', 'max-width') {
          margin-bottom: var(--emu-common-spacing-brand-xs);
        }
      }
    }
  }

  .references {
    padding-bottom: 75px;

    @include mq('large') {
      padding-top: 55px;
      padding-bottom: 58px;
    }
  }
}
