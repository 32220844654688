.header {
  &__logo {
    &-container {
      @include mq('large', 'max-width') {
        padding-bottom: 12px;
      }
    }

    &-wrapper {
      img {
        height: 26px; // as per figma
      }
    }
  }
}
