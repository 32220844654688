footer.experiencefragment {
  .footer {
    &__wrapper {
      padding-bottom: 39px;

      @include mq('medium') {
        padding-bottom: 58px;
      }
    }
  }
}
