#outcomes {
  .outcomes {
    &__teaser {
      background: #e4e7ec; // this color is used only once

      .content-container {
        @include mq('large') {
          padding-bottom: 122px;
        }

        .title-lockup {
          @include mq('large') {
            max-width: 576px;
          }
        }

        .emu-teaser {
          &__title {
            margin-bottom: 15px;

            @include mq('large') {
              letter-spacing: -0.9px;
            }

            @include mq('large', 'max-width') {
              sup {
                top: 1px;
              }
            }
          }

          &__subtitle {
            margin-top: 18px;
            font-size: var(--emu-common-font-sizes-headline-medium);
            line-height: var(--emu-common-line-heights-narrow-large);
            font-family: var(--emu-semantic-font-families-heading);
            font-weight: var(--emu-semantic-font-weight-bold);
            letter-spacing: -0.1px;

            @include mq('x-large') {
              margin-top: auto;
              line-height: var(--emu-common-line-heights-wide-large);
              max-width: 424px; // as per design
            }
          }

          &__description {
            @include mq('x-large') {
              margin-top: var(--emu-common-spacing-none);
            }

            p {
              font-size: var(--emu-common-font-sizes-body-small);
              font-family: var(--emu-common-font-families-sans);
              line-height: var(--emu-common-line-heights-narrow-jumbo);
              letter-spacing: 1px;
              line-height: 170%; // no variable
              margin-top: 15px;

              @include mq('large') {
                font-size: var(--emu-common-font-sizes-body-large);
                line-height: var(--emu-common-line-heights-narrow-xxxl);
                letter-spacing: normal;
                margin-top: 30px;
              }
            }
          }

          &__disclaimer {
            margin-top: var(--emu-common-spacing-brand-small);

            @include mq('large') {
              padding-top: var(--emu-common-spacing-brand-xs);
              padding-bottom: 23px;
            }
          }
        }
      }
    }

    &__journey-tab {
      .aaaem-tabs {
        &__tablist {
          padding-top: 33px;

          @include mq('large') {
            padding-top: 27px;
          }

          a {
            font-size: var(--emu-common-font-sizes-headline-medium);
            padding-bottom: 19px;
            letter-spacing: -0.2px;

            @include mq('large') {
              padding-bottom: var(--emu-common-spacing-medium);
            }
          }
        }
      }
    }

    &__references {
      padding-bottom: 59px;

      @include mq('large') {
        padding-top: var(--emu-common-spacing-brand-medium);
        padding-bottom: 58px;
      }
    }
  }
}
