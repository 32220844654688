#home {
  .home {
    // main hero at the top of the page
    &__hero-teaser {
      .teaser-container {
        .content-container {
          @include mq('x-large', 'max-width') {
            padding-top: 45px;
            padding-right: 46px;
          }

          .emu-teaser {
            &__title {
              @include mq('x-large') {
                letter-spacing: -0.9px;
              }

              sup {
                font-size: 60%;
                top: 3px;
                letter-spacing: 1px;
              }
            }

            &__description {
              p {
                @include mq('x-large') {
                  line-height: 111%; // needed to match the design
                }
              }
            }

            &__disclaimer {
              @include mq('x-large', 'max-width') {
                margin-top: 31px;
              }
            }
          }
        }
      }
    }

    // intro section after the hero component
    &__intro {
      @include mq('large', 'max-width') {
        padding-top: 36px;
        padding-bottom: 46px;
      }

      @include mq('large') {
        padding-top: 62px;
        padding-bottom: 94px;
      }

      .intro-box {
        &__title {
          letter-spacing: -0.6px;
          margin-bottom: 17px;

          @include mq('large', 'max-width') {
            font-size: var(--emu-common-font-sizes-headline-large);
            line-height: 102%;
          }

          @include mq('large') {
            margin-bottom: 5px;
            letter-spacing: -2.14px;
            max-width: 812px; // to match figma
          }
        }

        &__pretitle {
          p,
          li {
            letter-spacing: 0.1px;

            @include mq('large') {
              margin-left: 6px;
            }
          }
        }
      }
    }

    // statistics cards
    &__stats {
      @include mq('large') {
        margin-bottom: 1px;
      }

      .brand-card {
        &--2x {
          .brand-card {
            &__subtitle {
              max-width: none;
            }

            &__title {
              @include mq('large') {
                margin-bottom: 12px;
              }
            }

            &__disc {
              @include mq('large') {
                max-width: 95%; // to match figma
              }
            }
          }
        }

        &__content {
          padding-top: var(--emu-common-spacing-large);

          @include mq('large') {
            padding-top: 52px;
          }
        }

        &__title {
          @include mq('large') {
            margin-bottom: 19px;
          }
        }

        &__subtitle {
          line-height: var(--emu-common-line-heights-narrow-medium);
          margin-bottom: 15px;

          @include mq('large', 'max-width') {
            max-width: 75%;
          }

          @include mq('large') {
            line-height: var(--emu-common-line-heights-custom-regular);
            letter-spacing: 0;
            margin-bottom: 19px;
          }
        }

        &__disc {
          sup {
            top: 2px;
          }
        }
      }
    }

    &__img-map {
      .interactive-map {
        &__content {
          @include mq('large', 'max-width') {
            padding-bottom: 29px;
          }

          @include mq('large') {
            padding-top: 60px;
          }
        }

        &__title {
          @include mq('large') {
            margin-bottom: 17px;
          }

          .cmp-title__text {
            font-size: var(--emu-common-font-sizes-headline-large);

            @include mq('large') {
              font-size: 56px;
              letter-spacing: -1px;
            }
          }
        }

        &__subtitle {
          margin-bottom: 15px;

          @include mq('large') {
            margin-bottom: 22px;
          }
        }
      }
    }

    // syringe map
    &__teaser {
      .teaser-container {
        .content-container {
          padding-top: 37px;

          @include mq('x-large') {
            padding-top: 61px;
          }

          .title-lockup {
            max-width: 842px; // As per design
          }

          .emu-teaser {
            &__title {
              @include mq('x-large', 'max-width') {
                font-size: var(--emu-common-font-sizes-headline-large);
                margin-bottom: 19px;
                max-width: 95%;
              }

              @include mq('x-large') {
                letter-spacing: -1.1px;
                margin-bottom: 22px;
              }
            }

            &__description {
              p {
                line-height: 108%;
              }
            }

            &__action-container {
              margin-top: 14px;
              margin-bottom: 65px;

              @include mq('x-large') {
                margin-top: 23px;
                margin-bottom: var(--emu-common-spacing-none);
              }
            }
          }
        }
      }
    }

    // Intro box above the timeline
    &__journey-intro {
      padding-top: 38px;
      padding-bottom: 47px;

      @include mq('large') {
        padding-top: 62px;
        padding-bottom: 96px;
      }

      .intro-box {
        &__title {
          letter-spacing: -0.6px; // to match the design
          margin-bottom: 18px;

          @include mq('large') {
            letter-spacing: -2px; // to match the design
            margin-bottom: var(--emu-common-spacing-xs);
          }
        }

        &__pretitle {
          letter-spacing: 0.2px; // to match the design

          @include mq('large') {
            letter-spacing: 0.1px; // to match the design
          }
        }
      }
    }

    &__carousel {
      padding-bottom: var(--emu-common-spacing-none);
      padding-top: var(--emu-common-spacing-none);

      .emu-carousel {
        &__content {
          display: flex;
          justify-content: stretch;
        }

        &__action {
          margin-top: var(--emu-common-spacing-none);
        }
      }

      .carouselslide,
      .emu-carousel__item-container,
      .emu-carousel__item-container > .container,
      .img-txt-grid,
      .img-txt-grid > .container,
      .img-txt-grid__inner {
        height: 100%;
      }

      .img-txt-grid__inner > .container:nth-child(2) {
        flex: 1;
      }

      .tns-nav {
        top: 408px;
        left: 104px;
        transform: translateX(-50%);

        @include mq('390px') {
          top: 433px;
        }

        @include mq('large') {
          top: 68px;
          left: 50%;
          margin-left: 144px;
        }
      }

      .tns-controls {
        position: absolute;
        width: 50%;
        left: 0;
        top: 50%;
      }

      .img-txt-grid {
        &__section-content {
          height: 100%;
        }

        &__content {
          &--top {
            padding-top: 93px;
            padding-bottom: 22px;

            @include mq('large') {
              padding-top: 129px;
            }
          }

          &--bottom {
            padding-top: 45px;
            padding-bottom: var(--emu-common-spacing-small);

            @include mq('x-large') {
              padding-top: 173px;
              padding-bottom: 23px;
            }
          }
        }

        &__title {
          @include mq('large') {
            padding-right: var(--emu-common-spacing-none);
          }

          .cmp-title__text {
            letter-spacing: 0; // to match the design

            @include mq('large') {
              letter-spacing: -0.6px; // to match the design
            }
          }

          &-container {
            margin-bottom: 18px;

            @include mq('large') {
              margin-bottom: 40px;
            }

            img {
              width: 200px; // No figma design for this. Made it reasonably good
              height: auto;
              margin-bottom: 20px;

              @include mq('large') {
                width: 300px; // as per figma
              }
            }
          }
        }

        &__subtitle {
          margin-bottom: var(--emu-common-spacing-none);
        }

        &__disc {
          &-title {
            .cmp-title__text {
              font-size: var(--emu-common-font-sizes-display-medium);
              line-height: 0.88; // to match the design
              letter-spacing: -2.4px; // to match the design
              margin-bottom: var(--emu-common-spacing-none);

              @include mq('large') {
                font-size: var(--emu-common-font-sizes-display-xlarge);
                letter-spacing: -4.08px; // to match the design
              }
            }
          }
        }

        &__img {
          img {
            @include mq('x-large', 'max-width') {
              object-fit: contain;
            }
          }
        }

        &__inner {
          @include mq('large') {
            border-top: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-black);
          }
        }
      }
    }
  }
}
