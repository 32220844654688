.pre-footer {
  .intro-box {
    padding-top: 39px;
    padding-bottom: 25px;
    border-bottom: var(--emu-common-border-width-thin) solid
      var(--emu-common-colors-white);

    @include mq('large') {
      padding-top: 58px;
      padding-bottom: 77px;
    }

    .cmp-title__text.emu-title__text {
      @include mq('large') {
        letter-spacing: -1px;
      }
    }
  }

  .img-txt-grid {
    &__section {
      &--content {
        padding-top: 37px;
        padding-bottom: 33px;

        @include mq('large', 'max-width') {
          padding-right: var(--emu-common-spacing-medium);
        }

        @include mq('large') {
          padding-top: var(--emu-common-spacing-xl);
          padding-bottom: var(--emu-common-spacing-brand-medium);
        }
      }
    }

    &__txt {
      margin-bottom: 25px;
    }

    &__cta {
      margin-bottom: var(--emu-common-spacing-large);
    }

    &__title {
      margin-bottom: 18px;

      @include mq('large') {
        margin-bottom: 15px;
      }
    }
  }

  &__img-txt-grid {
    &--consultation {
      .img-txt-grid {
        &__section {
          &--content {
            @include mq('large', 'max-width') {
              padding-top: 37px;
            }
          }
        }
      }
    }
  }
}
