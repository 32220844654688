[data-component='container-isi-v2'] {
  .isi-container {
    border: var(--emu-common-border-width-none);
  }

  .aaaem-isi {
    &-banner {
      @include max-container;
      position: relative;

      @include mq('large') {
        align-items: flex-start;
      }

      button {
        padding: var(--emu-common-spacing-none);
        position: absolute;
        top: 16px;
        right: 20px;

        @include mq('large') {
          top: 15px;
          right: 60px;
        }

        span {
          font-size: 0; // needed to hide the default X
        }

        svg {
          margin-right: var(--emu-common-spacing-none);
        }
      }

      p {
        font-size: var(--emu-common-font-sizes-captions-large);
        line-height: var(--emu-common-line-heights-narrow-jumbo);
        margin-top: var(--emu-common-spacing-none);
      }
    }

    &-container {
      &__banner-content {
        display: none;

        @include mq('large') {
          width: calc(
            50% - 2px
          ); // intentional calculation to match the design closer
          padding-right: var(--emu-common-spacing-brand-xs);
          display: block;
        }

        &[data-ref='banner-content-on-open'] {
          display: block;

          @include mq('large') {
            width: 50%;
          }

          b {
            display: block;

            @include mq('large') {
              display: inline-block;
              letter-spacing: -0.1px;
            }
          }
        }
      }

      &__isi-content {
        @include max-container;
      }
    }
  }

  .isi-content {
    &__top-container {
      @include mq('large') {
        display: flex;
        margin-bottom: 22px;
      }

      > .container {
        &:first-child {
          @include mq('large') {
            width: 450px;
            padding-right: 27px;
          }
        }

        &:last-child {
          @include mq('large') {
            flex: 1;
          }
        }
      }
    }

    &__section {
      margin-bottom: 27px;

      &--indications {
        margin-bottom: 24px;

        .isi-content {
          &__title {
            margin-bottom: 23px;

            h2,
            h3,
            h4,
            h5 {
              letter-spacing: 0.4px;

              @include mq('large') {
                letter-spacing: 0.7px;
              }
            }
          }
        }
      }

      &--precautions {
        margin-bottom: 25px;
      }

      &--bottom-contraindications {
        margin-bottom: 29px;

        .isi-content {
          &__title {
            margin-bottom: 21px;

            @include mq('large') {
              margin-bottom: 23px;
            }

            h2,
            h3,
            h4,
            h5 {
              letter-spacing: 0.5px;
            }
          }
        }
      }

      &--warnings {
        margin-bottom: 29px;
      }

      &--adverse-events {
        margin-bottom: 25px;

        .isi-content {
          &__title {
            margin-bottom: 24px;

            h2,
            h3,
            h4,
            h5 {
              letter-spacing: 0px;
            }
          }
        }
      }
    }

    &__title,
    &__txt {
      h2,
      h3,
      h4,
      h5,
      p,
      li {
        font-size: var(--emu-common-font-sizes-body-regular);
        line-height: 26px; // no variable available
      }
    }

    &__title {
      margin-bottom: 26px;

      h2,
      h3,
      h4,
      h5 {
        font-weight: var(--emu-common-font-weight-600);
        letter-spacing: 0.16px;
      }
    }

    &__txt {
      h2,
      h3,
      h4,
      h5 {
        letter-spacing: 0.4px;

        & + p,
        & + ul {
          margin-top: -3px; // needed to match the design
        }
      }

      p + p,
      li + li {
        margin-top: 26px;
      }

      ul {
        list-style: none;
        padding-left: 24px;
        margin-bottom: var(--emu-common-spacing-none);
        margin-top: -3px; // needed to match the design
      }

      li {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 12px;
          left: -14px;
          width: 4px;
          height: 4px;
          background-color: currentColor;
          border-radius: 50%;
        }
      }

      a {
        text-decoration: inherit;
        display: inline-block;

        &,
        &:hover,
        &:focus,
        &:active {
          color: inherit;
        }
      }
    }

    &__main-title {
      .cmp-title__text {
        font-size: 28px;
        font-weight: 500; // no variable for this
        line-height: 36px;
        letter-spacing: -0.6px;
        border-top: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-black);
        padding-top: 23px;
        margin-bottom: 27px;

        @include mq('large') {
          padding-top: 44px;
          letter-spacing: -1.25px;
          font-size: 36px;
          line-height: 44px;
          margin-bottom: 50px;
        }
      }

      sup {
        font-size: 100%;
        top: 10px;

        @include mq('large') {
          font-size: 70%;
        }
      }
    }

    &__main-content {
      display: flex;
      flex-direction: column-reverse;

      @include mq('large') {
        flex-direction: row;
      }

      > .container {
        @include mq('large') {
          flex: 1;
        }

        &:first-child {
          @include mq('large') {
            padding-right: var(--emu-common-spacing-brand-xs);
          }
        }
      }
    }
  }
}
