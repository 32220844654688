.ba-item {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  border-top: var(--emu-common-border-width-thin) solid
    var(--emu-common-colors-black);

  &__content {
    padding-top: 43px;
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-brand-small);

    @include mq('large') {
      padding-left: var(--emu-common-spacing-brand-medium);
      padding-right: var(--emu-common-spacing-brand-medium);
      padding-bottom: var(--emu-common-spacing-brand-medium);
      padding-top: 58px;
    }
  }

  &__title {
    margin-bottom: 33px;
    padding-top: var(--emu-common-spacing-none);

    @include mq('large') {
      padding-top: 6px;
    }

    .cmp-title__text {
      letter-spacing: -0.2px; //to match with design

      @include mq('large') {
        letter-spacing: -0.64px; //to match with design
      }
    }
  }

  &__disc {
    padding-right: var(--emu-common-spacing-none);
  }

  &__img-label {
    p {
      @include mq('large') {
        padding-top: var(--emu-common-spacing-small);
      }
    }
  }
}
