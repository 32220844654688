
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-component-actions-button-text-typography-narrow: 400 14px/150% 'Graphik',sans-serif !default;
$emu-component-actions-button-text-typography-wide: 400 14px/150% 'Graphik',sans-serif !default;
$emu-component-actions-button-text-color-primary-outline-default-light: #000000 !default;
$emu-component-actions-button-text-color-primary-outline-default-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-primary-outline-disabled-light: #000000 !default;
$emu-component-actions-button-text-color-primary-outline-disabled-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-secondary-outline-hover-light: #000000 !default;
$emu-component-actions-button-text-color-secondary-outline-hover-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-secondary-outline-focus-light: #000000 !default;
$emu-component-actions-button-text-color-secondary-outline-focus-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-secondary-outline-active-light: #000000 !default;
$emu-component-actions-button-text-color-secondary-outline-active-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-secondary-outline-default-light: #000000 !default;
$emu-component-actions-button-text-color-secondary-outline-default-dark: #FFFFFF !default;
$emu-component-actions-button-text-color-secondary-outline-disabled-light: #B2B2B2 !default;
$emu-component-actions-button-text-color-secondary-outline-disabled-dark: #FFFFFF !default;
$emu-component-actions-button-border-radius-primary-filled: 8px !default;
$emu-component-actions-button-border-radius-primary-outline: 8px !default;
$emu-component-actions-button-border-radius-secondary-filled: 8px !default;
$emu-component-actions-button-border-radius-secondary-outline: 8px !default;
$emu-component-actions-button-border-width-primary-filled: 1px !default;
$emu-component-actions-button-border-width-primary-outline: 1px !default;
$emu-component-actions-button-border-width-secondary-filled: 1px !default;
$emu-component-actions-button-border-width-secondary-outline: 1px !default;
$emu-component-actions-button-padding-narrow: 13px 12px !default;
$emu-component-actions-button-padding-wide: 13px 12px !default;
$emu-component-actions-button-outline-width-primary-filled: medium !default;
$emu-component-actions-button-outline-width-primary-outline: medium !default;
$emu-component-actions-button-outline-width-secondary-filled: medium !default;
$emu-component-actions-button-outline-width-secondary-outline: medium !default;
$emu-component-actions-button-color-border-primary-filled-hover-light: #F9C7BD !default;
$emu-component-actions-button-color-border-primary-filled-hover-dark: #000000 !default;
$emu-component-actions-button-color-border-primary-filled-active-light: #FCE3DE !default;
$emu-component-actions-button-color-border-primary-filled-active-dark: #000000 !default;
$emu-component-actions-button-color-border-secondary-filled-focus-light: #787676 !default;
$emu-component-actions-button-color-border-secondary-filled-focus-dark: #FFFFFF !default;
$emu-component-actions-button-color-border-secondary-filled-hover-light: #666666 !default;
$emu-component-actions-button-color-border-secondary-filled-hover-dark: #FFFFFF !default;
$emu-component-actions-button-color-border-secondary-filled-active-light: #b3b3b3 !default;
$emu-component-actions-button-color-border-secondary-filled-active-dark: #FFFFFF !default;
$emu-component-actions-button-color-border-secondary-outline-focus-light: #E8E8E8 !default;
$emu-component-actions-button-color-border-secondary-outline-focus-dark: #E8E8E8 !default;
$emu-component-actions-button-color-border-secondary-outline-disabled-light: #B2B2B2 !default;
$emu-component-actions-button-color-border-secondary-outline-disabled-dark: #FFFFFF !default;
$emu-component-actions-button-color-background-primary-outline-focus-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-primary-outline-focus-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-primary-outline-hover-light: #F9C7BD !default;
$emu-component-actions-button-color-background-primary-outline-hover-dark: #000000 !default;
$emu-component-actions-button-color-background-primary-outline-default-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-primary-outline-default-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-background-secondary-outline-focus-light: #E8E8E8 !default;
$emu-component-actions-button-color-background-secondary-outline-focus-dark: #FFFFFF !default;
$emu-component-actions-button-color-background-secondary-outline-hover-light: #E8E8E8 !default;
$emu-component-actions-button-color-background-secondary-outline-hover-dark: #FFFFFF !default;
$emu-component-actions-button-color-background-secondary-outline-active-light: #aaaaaa !default;
$emu-component-actions-button-color-background-secondary-outline-active-dark: #FFFFFF !default;
$emu-component-actions-button-color-outline-color-primary-filled-focus-light: #000000 !default;
$emu-component-actions-button-color-outline-color-primary-filled-focus-dark: #000000 !default;
$emu-component-actions-button-color-outline-color-primary-filled-active-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-filled-active-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-filled-default-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-filled-default-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-filled-disabled-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-outline-focus-light: #F07459 !default;
$emu-component-actions-button-color-outline-color-primary-outline-focus-dark: #000000 !default;
$emu-component-actions-button-color-outline-color-primary-outline-hover-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-outline-hover-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-outline-active-light: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-primary-outline-active-dark: rgba(0,0,0,0) !default;
$emu-component-actions-button-color-outline-color-secondary-filled-focus-light: #000000 !default;
$emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: #FFFFFF !default;
$emu-component-actions-button-color-outline-color-secondary-outline-focus-light: #F07459 !default;
$emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: #000000 !default;
$emu-component-container-max-width: 1600px !default;
$emu-component-lists-navigation-banner-color-background-light: #FFFFFF !default;
$emu-component-lists-navigation-banner-item-border-width: 0px !default;
$emu-component-lists-navigation-banner-item-color-background-hover-light: rgba(0,0,0,0) !default;
$emu-component-lists-navigation-banner-item-text-color-default-light: #000000 !default;
$emu-component-lists-navigation-banner-item-text-color-hover-light: #000000 !default;
$emu-component-lists-navigation-dropdown-border-width: 0px !default;
$emu-component-lists-navigation-dropdown-item-text-color-default-light: #000000 !default;
$emu-component-lists-navigation-dropdown-item-text-color-hover-light: #000000 !default;
$emu-component-lists-navigation-dropdown-item-color-background-hover-light: rgba(0,0,0,0) !default;
$emu-component-lists-navigation-text-padding-top-narrow: 12px !default;
$emu-component-lists-navigation-text-padding-top-wide: 12px !default;
$emu-component-lists-navigation-text-padding-right-narrow: 10px !default;
$emu-component-lists-navigation-text-padding-right-wide: 10px !default;
$emu-component-lists-navigation-text-padding-bottom-narrow: 12px !default;
$emu-component-lists-navigation-text-padding-bottom-wide: 12px !default;
$emu-component-lists-navigation-text-padding-left-narrow: 12px !default;
$emu-component-lists-navigation-text-padding-left-wide: 12px !default;
$emu-component-lists-accordion-margin-narrow: 0px !default;
$emu-component-lists-accordion-margin-wide: 0px !default;
$emu-component-lists-accordion-item-header-padding-narrow: 32px 16px 21px !default;
$emu-component-lists-accordion-item-header-padding-wide: 35px 56px 28px !default;
$emu-component-lists-accordion-item-header-text-typography-narrow: 700 24px/110% 'NeueHelvetica65Medium',sans-serif !default;
$emu-component-lists-accordion-item-header-text-typography-wide: 700 24px/110% 'NeueHelvetica65Medium',sans-serif !default;
$emu-component-lists-accordion-item-header-icon-size-height-narrow: 32px !default;
$emu-component-lists-accordion-item-header-icon-size-height-wide: 32px !default;
$emu-component-lists-accordion-item-header-icon-size-width-narrow: 32px !default;
$emu-component-lists-accordion-item-header-icon-size-width-wide: 32px !default;
$emu-component-lists-accordion-item-header-icon-color-fill-open-light: #000000 !default;
$emu-component-lists-accordion-item-header-icon-color-fill-closed-light: #F07459 !default;
$emu-component-lists-accordion-item-header-icon-margin-narrow: -9px 0px 0px 30px !default;
$emu-component-lists-accordion-item-header-icon-margin-wide: -9px 0px 0px 30px !default;
$emu-component-lists-accordion-item-body-padding-narrow: 16px 23px 16px 16px !default;
$emu-component-lists-accordion-item-body-padding-wide: 0px 87px 32px 56px !default;
$emu-component-containers-carousel-padding-narrow: 57px 0px 24px 0px !default;
$emu-component-containers-carousel-padding-wide: 80px 0px 56px 0px !default;
$emu-component-containers-carousel-indicators-dots-color-background-default-light: rgba(0,0,0,0) !default;
$emu-component-containers-carousel-indicators-dots-color-background-active-light: #000000 !default;
$emu-component-containers-carousel-indicators-dots-color-background-active-dark: #FFFFFF !default;
$emu-component-containers-carousel-indicators-dots-color-border-default-light: #959292 !default;
$emu-component-containers-carousel-indicators-dots-color-border-active-light: #000000 !default;
$emu-component-containers-carousel-indicators-dots-color-border-active-dark: #FFFFFF !default;
$emu-component-containers-carousel-indicators-dots-size-narrow: 10px !default;
$emu-component-containers-carousel-indicators-dots-size-wide: 10px !default;
$emu-component-containers-carousel-indicators-dots-gap-narrow: 0px !default;
$emu-component-containers-carousel-indicators-dots-gap-wide: 0px !default;
$emu-component-containers-carousel-indicators-dots-padding-narrow: 0px !default;
$emu-component-containers-carousel-indicators-dots-padding-wide: 0px !default;
$emu-component-containers-carousel-border-width-narrow: 0px !default;
$emu-component-containers-carousel-border-width-wide: 0px !default;
$emu-component-containers-carousel-controls-padding-narrow: 24px !default;
$emu-component-containers-carousel-controls-padding-wide: 24px !default;
$emu-component-containers-carousel-controls-color-background-light: #ffffff99 !default;
$emu-component-containers-carousel-frame-border-width-default-narrow: 0px !default;
$emu-component-containers-carousel-frame-border-width-default-wide: 0px !default;
$emu-component-containers-carousel-frame-border-width-active-narrow: 0px !default;
$emu-component-containers-carousel-frame-border-width-active-wide: 0px !default;
$emu-component-containers-carousel-color-background-light: rgba(0,0,0,0) !default;
$emu-component-containers-carousel-color-background-dark: rgba(0,0,0,0) !default;
$emu-component-containers-carousel-color-border-light: rgba(0,0,0,0) !default;
$emu-component-containers-carousel-color-border-dark: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-gap-horizontal-narrow: 0px !default;
$emu-component-containers-tabs-gap-horizontal-wide: 0px !default;
$emu-component-containers-tabs-gap-vertical-narrow: 0px !default;
$emu-component-containers-tabs-gap-vertical-wide: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-top-narrow: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-top-wide: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-right-narrow: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-right-wide: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-bottom-narrow: 19px !default;
$emu-component-containers-tabs-navigation-item-padding-bottom-wide: 34px !default;
$emu-component-containers-tabs-navigation-item-padding-left-narrow: 0px !default;
$emu-component-containers-tabs-navigation-item-padding-left-wide: 0px !default;
$emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: 21px !default;
$emu-component-containers-tabs-navigation-item-gap-horizontal-wide: 29px !default;
$emu-component-containers-tabs-navigation-item-border-width-top-default: 0px !default;
$emu-component-containers-tabs-navigation-item-border-width-top-active: 0px !default;
$emu-component-containers-tabs-navigation-item-border-width-right-default: 0px !default;
$emu-component-containers-tabs-navigation-item-border-width-right-active: 0px !default;
$emu-component-containers-tabs-navigation-item-border-width-bottom-default: 4px !default;
$emu-component-containers-tabs-navigation-item-border-width-bottom-active: 4px !default;
$emu-component-containers-tabs-navigation-item-border-width-left-default: 0px !default;
$emu-component-containers-tabs-navigation-item-border-width-left-active: 0px !default;
$emu-component-containers-tabs-navigation-item-color-border-default-light: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-navigation-item-color-border-default-dark: #000000 !default;
$emu-component-containers-tabs-navigation-item-color-border-active-light: #000000 !default;
$emu-component-containers-tabs-navigation-item-color-border-active-dark: #000000 !default;
$emu-component-containers-tabs-navigation-item-color-background-default-light: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-navigation-item-color-background-default-dark: #000000 !default;
$emu-component-containers-tabs-navigation-item-color-background-active-light: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-navigation-item-color-background-active-dark: #000000 !default;
$emu-component-containers-tabs-navigation-item-text-color-default-light: #000000 !default;
$emu-component-containers-tabs-navigation-item-text-color-default-dark: #FFFFFF !default;
$emu-component-containers-tabs-navigation-item-text-color-active-light: #000000 !default;
$emu-component-containers-tabs-navigation-item-text-color-active-dark: #FFFFFF !default;
$emu-component-containers-tabs-content-padding-top-narrow: 0px !default;
$emu-component-containers-tabs-content-padding-top-wide: 0px !default;
$emu-component-containers-tabs-content-padding-right-narrow: 0px !default;
$emu-component-containers-tabs-content-padding-right-wide: 0px !default;
$emu-component-containers-tabs-content-padding-bottom-narrow: 0px !default;
$emu-component-containers-tabs-content-padding-bottom-wide: 0px !default;
$emu-component-containers-tabs-content-padding-left-narrow: 0px !default;
$emu-component-containers-tabs-content-padding-left-wide: 0px !default;
$emu-component-containers-tabs-content-gap-horizontal-narrow: 0px !default;
$emu-component-containers-tabs-content-gap-horizontal-wide: 0px !default;
$emu-component-containers-tabs-content-gap-vertical-narrow: 0px !default;
$emu-component-containers-tabs-content-gap-vertical-wide: 0px !default;
$emu-component-containers-tabs-content-color-background-light: rgba(0,0,0,0) !default;
$emu-component-containers-tabs-content-color-background-dark: rgba(0,0,0,0) !default;
$emu-component-containers-isi-header-padding-top-narrow: 16px !default;
$emu-component-containers-isi-header-padding-top-wide: 16px !default;
$emu-component-containers-isi-header-padding-right-narrow: 16px !default;
$emu-component-containers-isi-header-padding-right-wide: 56px !default;
$emu-component-containers-isi-header-padding-bottom-narrow: 16px !default;
$emu-component-containers-isi-header-padding-bottom-wide: 13px !default;
$emu-component-containers-isi-header-padding-left-narrow: 16px !default;
$emu-component-containers-isi-header-padding-left-wide: 56px !default;
$emu-component-containers-isi-header-color-background-light: rgba(255, 255, 255, 0.8) !default;
$emu-component-containers-isi-body-padding-top-narrow: 24px !default;
$emu-component-containers-isi-body-padding-top-wide: 56px !default;
$emu-component-containers-isi-body-padding-right-narrow: 16px !default;
$emu-component-containers-isi-body-padding-right-wide: 56px !default;
$emu-component-containers-isi-body-padding-bottom-narrow: 25px !default;
$emu-component-containers-isi-body-padding-bottom-wide: 32px !default;
$emu-component-containers-isi-body-padding-left-narrow: 16px !default;
$emu-component-containers-isi-body-padding-left-wide: 56px !default;
$emu-component-cards-teaser-padding-narrow: 24px 16px 24px !default;
$emu-component-cards-teaser-padding-wide: 24px 16px 24px !default;
$emu-component-cards-teaser-disclaimer-padding-narrow: 0px !default;
$emu-component-cards-teaser-disclaimer-padding-wide: 0px !default;
$emu-component-cards-teaser-description-padding-narrow: 0px !default;
$emu-component-cards-teaser-description-padding-wide: 0px !default;
$emu-component-cards-teaser-title-max-width: 100% !default;
$emu-component-cards-teaser-title-margin-narrow: 0px !default;
$emu-component-cards-teaser-title-margin-wide: 0px !default;
$emu-component-layers-modal-scrim-color-background: rgba(0,0,0,0.80) !default;
$emu-component-layers-modal-body-sizing-width: 100% !default;
$emu-component-layers-modal-body-padding-top-narrow: 0px !default;
$emu-component-layers-modal-body-padding-top-wide: 0px !default;
$emu-component-layers-modal-body-padding-right-narrow: 0px !default;
$emu-component-layers-modal-body-padding-right-wide: 0px !default;
$emu-component-layers-modal-body-padding-bottom-narrow: 0px !default;
$emu-component-layers-modal-body-padding-bottom-wide: 0px !default;
$emu-component-layers-modal-body-padding-left-narrow: 0px !default;
$emu-component-layers-modal-body-padding-left-wide: 0px !default;
$emu-component-layers-modal-body-color-background-dark: #FFFFFF !default;
$emu-component-layers-modal-body-border-width-all: 0px !default;
$emu-component-layers-modal-actions-close-padding-top: 0px !default;
$emu-component-layers-modal-actions-close-padding-right: 0px !default;
$emu-component-layers-modal-actions-close-padding-bottom: 0px !default;
$emu-component-layers-modal-actions-close-padding-left: 0px !default;
$emu-component-layers-cookie-consent-banner-max-width: 665px !default;
$emu-component-layers-cookie-consent-banner-text-color-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-text-color-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-color-background-light: #000000 !default;
$emu-component-layers-cookie-consent-banner-color-background-dark: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !default;
$emu-component-layers-cookie-consent-banner-padding-top-narrow: 16px !default;
$emu-component-layers-cookie-consent-banner-padding-top-wide: 16px !default;
$emu-component-layers-cookie-consent-banner-padding-right-narrow: 16px !default;
$emu-component-layers-cookie-consent-banner-padding-right-wide: 16px !default;
$emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 14px !default;
$emu-component-layers-cookie-consent-banner-padding-bottom-wide: 14px !default;
$emu-component-layers-cookie-consent-banner-padding-left-narrow: 16px !default;
$emu-component-layers-cookie-consent-banner-padding-left-wide: 16px !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: 400 8px/145% 'Graphik',sans-serif !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: 400 8px/145% 'Graphik',sans-serif !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-typography-large: 400 32px/110% 'NeueHelvetica65Medium',sans-serif !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-light: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 15px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 15px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 15px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 15px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 15px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 15px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 15px !default;
$emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 15px !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 15px !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: 24px !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: 0px !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: 0px !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: 0px !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: 0px !default;
$emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 0px !default;
$emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px !default;
$emu-component-layers-cookie-consent-banner-settings-button-outline-width: thin !default;
$emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: 8px !default;
$emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: 8px !default;
$emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: 8px !default;
$emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: 8px !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-light: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: #000000 !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: #FFFFFF !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: rgba(0,0,0,0) !default;
$emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: rgba(0,0,0,0) !default;
$emu-component-ingredients-image-map-buttons-size-height: 32px !default;
$emu-component-ingredients-image-map-buttons-size-width: 32px !default;
$emu-component-ingredients-image-map-buttons-color-background-light: rgba(0,0,0,0) !default;

$component: (
  'component': (
    'actions': (
      'button': (
        'text': (
          'typography': (
            'narrow': $emu-component-actions-button-text-typography-narrow,
            'wide': $emu-component-actions-button-text-typography-wide
          ),
          'color': (
            'primary': (
              'outline': (
                'default': (
                  'light': $emu-component-actions-button-text-color-primary-outline-default-light,
                  'dark': $emu-component-actions-button-text-color-primary-outline-default-dark
                ),
                'disabled': (
                  'light': $emu-component-actions-button-text-color-primary-outline-disabled-light,
                  'dark': $emu-component-actions-button-text-color-primary-outline-disabled-dark
                )
              )
            ),
            'secondary': (
              'outline': (
                'hover': (
                  'light': $emu-component-actions-button-text-color-secondary-outline-hover-light,
                  'dark': $emu-component-actions-button-text-color-secondary-outline-hover-dark
                ),
                'focus': (
                  'light': $emu-component-actions-button-text-color-secondary-outline-focus-light,
                  'dark': $emu-component-actions-button-text-color-secondary-outline-focus-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-text-color-secondary-outline-active-light,
                  'dark': $emu-component-actions-button-text-color-secondary-outline-active-dark
                ),
                'default': (
                  'light': $emu-component-actions-button-text-color-secondary-outline-default-light,
                  'dark': $emu-component-actions-button-text-color-secondary-outline-default-dark
                ),
                'disabled': (
                  'light': $emu-component-actions-button-text-color-secondary-outline-disabled-light,
                  'dark': $emu-component-actions-button-text-color-secondary-outline-disabled-dark
                )
              )
            )
          )
        ),
        'borderRadius': (
          'primary': (
            'filled': $emu-component-actions-button-border-radius-primary-filled,
            'outline': $emu-component-actions-button-border-radius-primary-outline
          ),
          'secondary': (
            'filled': $emu-component-actions-button-border-radius-secondary-filled,
            'outline': $emu-component-actions-button-border-radius-secondary-outline
          )
        ),
        'borderWidth': (
          'primary': (
            'filled': $emu-component-actions-button-border-width-primary-filled,
            'outline': $emu-component-actions-button-border-width-primary-outline
          ),
          'secondary': (
            'filled': $emu-component-actions-button-border-width-secondary-filled,
            'outline': $emu-component-actions-button-border-width-secondary-outline
          )
        ),
        'padding': (
          'narrow': $emu-component-actions-button-padding-narrow,
          'wide': $emu-component-actions-button-padding-wide
        ),
        'outlineWidth': (
          'primary': (
            'filled': $emu-component-actions-button-outline-width-primary-filled,
            'outline': $emu-component-actions-button-outline-width-primary-outline
          ),
          'secondary': (
            'filled': $emu-component-actions-button-outline-width-secondary-filled,
            'outline': $emu-component-actions-button-outline-width-secondary-outline
          )
        ),
        'color': (
          'border': (
            'primary': (
              'filled': (
                'hover': (
                  'light': $emu-component-actions-button-color-border-primary-filled-hover-light,
                  'dark': $emu-component-actions-button-color-border-primary-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-border-primary-filled-active-light,
                  'dark': $emu-component-actions-button-color-border-primary-filled-active-dark
                )
              )
            ),
            'secondary': (
              'filled': (
                'focus': (
                  'light': $emu-component-actions-button-color-border-secondary-filled-focus-light,
                  'dark': $emu-component-actions-button-color-border-secondary-filled-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-border-secondary-filled-hover-light,
                  'dark': $emu-component-actions-button-color-border-secondary-filled-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-border-secondary-filled-active-light,
                  'dark': $emu-component-actions-button-color-border-secondary-filled-active-dark
                )
              ),
              'outline': (
                'focus': (
                  'light': $emu-component-actions-button-color-border-secondary-outline-focus-light,
                  'dark': $emu-component-actions-button-color-border-secondary-outline-focus-dark
                ),
                'disabled': (
                  'light': $emu-component-actions-button-color-border-secondary-outline-disabled-light,
                  'dark': $emu-component-actions-button-color-border-secondary-outline-disabled-dark
                )
              )
            )
          ),
          'background': (
            'primary': (
              'outline': (
                'focus': (
                  'light': $emu-component-actions-button-color-background-primary-outline-focus-light,
                  'dark': $emu-component-actions-button-color-background-primary-outline-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-background-primary-outline-hover-light,
                  'dark': $emu-component-actions-button-color-background-primary-outline-hover-dark
                ),
                'default': (
                  'light': $emu-component-actions-button-color-background-primary-outline-default-light,
                  'dark': $emu-component-actions-button-color-background-primary-outline-default-dark
                )
              )
            ),
            'secondary': (
              'outline': (
                'focus': (
                  'light': $emu-component-actions-button-color-background-secondary-outline-focus-light,
                  'dark': $emu-component-actions-button-color-background-secondary-outline-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-background-secondary-outline-hover-light,
                  'dark': $emu-component-actions-button-color-background-secondary-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-background-secondary-outline-active-light,
                  'dark': $emu-component-actions-button-color-background-secondary-outline-active-dark
                )
              )
            )
          ),
          'outlineColor': (
            'primary': (
              'filled': (
                'focus': (
                  'light': $emu-component-actions-button-color-outline-color-primary-filled-focus-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-filled-focus-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-outline-color-primary-filled-active-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-filled-active-dark
                ),
                'default': (
                  'light': $emu-component-actions-button-color-outline-color-primary-filled-default-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-filled-default-dark
                ),
                'disabled': (
                  'light': $emu-component-actions-button-color-outline-color-primary-filled-disabled-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-filled-disabled-dark
                )
              ),
              'outline': (
                'focus': (
                  'light': $emu-component-actions-button-color-outline-color-primary-outline-focus-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-outline-focus-dark
                ),
                'hover': (
                  'light': $emu-component-actions-button-color-outline-color-primary-outline-hover-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-outline-hover-dark
                ),
                'active': (
                  'light': $emu-component-actions-button-color-outline-color-primary-outline-active-light,
                  'dark': $emu-component-actions-button-color-outline-color-primary-outline-active-dark
                )
              )
            ),
            'secondary': (
              'filled': (
                'focus': (
                  'light': $emu-component-actions-button-color-outline-color-secondary-filled-focus-light,
                  'dark': $emu-component-actions-button-color-outline-color-secondary-filled-focus-dark
                )
              ),
              'outline': (
                'focus': (
                  'light': $emu-component-actions-button-color-outline-color-secondary-outline-focus-light,
                  'dark': $emu-component-actions-button-color-outline-color-secondary-outline-focus-dark
                )
              )
            )
          )
        )
      )
    ),
    'container': (
      'maxWidth': $emu-component-container-max-width
    ),
    'lists': (
      'navigation': (
        'banner': (
          'color': (
            'background': (
              'light': $emu-component-lists-navigation-banner-color-background-light
            )
          ),
          'item': (
            'borderWidth': $emu-component-lists-navigation-banner-item-border-width,
            'color': (
              'background': (
                'hover': (
                  'light': $emu-component-lists-navigation-banner-item-color-background-hover-light
                )
              )
            ),
            'text': (
              'color': (
                'default': (
                  'light': $emu-component-lists-navigation-banner-item-text-color-default-light
                ),
                'hover': (
                  'light': $emu-component-lists-navigation-banner-item-text-color-hover-light
                )
              )
            )
          )
        ),
        'dropdown': (
          'borderWidth': $emu-component-lists-navigation-dropdown-border-width,
          'item': (
            'text': (
              'color': (
                'default': (
                  'light': $emu-component-lists-navigation-dropdown-item-text-color-default-light
                ),
                'hover': (
                  'light': $emu-component-lists-navigation-dropdown-item-text-color-hover-light
                )
              )
            ),
            'color': (
              'background': (
                'hover': (
                  'light': $emu-component-lists-navigation-dropdown-item-color-background-hover-light
                )
              )
            )
          )
        ),
        'text': (
          'padding': (
            'top': (
              'narrow': $emu-component-lists-navigation-text-padding-top-narrow,
              'wide': $emu-component-lists-navigation-text-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-lists-navigation-text-padding-right-narrow,
              'wide': $emu-component-lists-navigation-text-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-lists-navigation-text-padding-bottom-narrow,
              'wide': $emu-component-lists-navigation-text-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-lists-navigation-text-padding-left-narrow,
              'wide': $emu-component-lists-navigation-text-padding-left-wide
            )
          )
        )
      ),
      'accordion': (
        'margin': (
          'narrow': $emu-component-lists-accordion-margin-narrow,
          'wide': $emu-component-lists-accordion-margin-wide
        ),
        'item': (
          'header': (
            'padding': (
              'narrow': $emu-component-lists-accordion-item-header-padding-narrow,
              'wide': $emu-component-lists-accordion-item-header-padding-wide
            ),
            'text': (
              'typography': (
                'narrow': $emu-component-lists-accordion-item-header-text-typography-narrow,
                'wide': $emu-component-lists-accordion-item-header-text-typography-wide
              )
            ),
            'icon': (
              'size': (
                'height': (
                  'narrow': $emu-component-lists-accordion-item-header-icon-size-height-narrow,
                  'wide': $emu-component-lists-accordion-item-header-icon-size-height-wide
                ),
                'width': (
                  'narrow': $emu-component-lists-accordion-item-header-icon-size-width-narrow,
                  'wide': $emu-component-lists-accordion-item-header-icon-size-width-wide
                )
              ),
              'color': (
                'fill': (
                  'open': (
                    'light': $emu-component-lists-accordion-item-header-icon-color-fill-open-light
                  ),
                  'closed': (
                    'light': $emu-component-lists-accordion-item-header-icon-color-fill-closed-light
                  )
                )
              ),
              'margin': (
                'narrow': $emu-component-lists-accordion-item-header-icon-margin-narrow,
                'wide': $emu-component-lists-accordion-item-header-icon-margin-wide
              )
            )
          ),
          'body': (
            'padding': (
              'narrow': $emu-component-lists-accordion-item-body-padding-narrow,
              'wide': $emu-component-lists-accordion-item-body-padding-wide
            )
          )
        )
      )
    ),
    'containers': (
      'carousel': (
        'padding': (
          'narrow': $emu-component-containers-carousel-padding-narrow,
          'wide': $emu-component-containers-carousel-padding-wide
        ),
        'indicators': (
          'dots': (
            'color': (
              'background': (
                'default': (
                  'light': $emu-component-containers-carousel-indicators-dots-color-background-default-light
                ),
                'active': (
                  'light': $emu-component-containers-carousel-indicators-dots-color-background-active-light,
                  'dark': $emu-component-containers-carousel-indicators-dots-color-background-active-dark
                )
              ),
              'border': (
                'default': (
                  'light': $emu-component-containers-carousel-indicators-dots-color-border-default-light
                ),
                'active': (
                  'light': $emu-component-containers-carousel-indicators-dots-color-border-active-light,
                  'dark': $emu-component-containers-carousel-indicators-dots-color-border-active-dark
                )
              )
            ),
            'size': (
              'narrow': $emu-component-containers-carousel-indicators-dots-size-narrow,
              'wide': $emu-component-containers-carousel-indicators-dots-size-wide
            ),
            'gap': (
              'narrow': $emu-component-containers-carousel-indicators-dots-gap-narrow,
              'wide': $emu-component-containers-carousel-indicators-dots-gap-wide
            ),
            'padding': (
              'narrow': $emu-component-containers-carousel-indicators-dots-padding-narrow,
              'wide': $emu-component-containers-carousel-indicators-dots-padding-wide
            )
          )
        ),
        'borderWidth': (
          'narrow': $emu-component-containers-carousel-border-width-narrow,
          'wide': $emu-component-containers-carousel-border-width-wide
        ),
        'controls': (
          'padding': (
            'narrow': $emu-component-containers-carousel-controls-padding-narrow,
            'wide': $emu-component-containers-carousel-controls-padding-wide
          ),
          'color': (
            'background': (
              'light': $emu-component-containers-carousel-controls-color-background-light
            )
          )
        ),
        'frame': (
          'borderWidth': (
            'default': (
              'narrow': $emu-component-containers-carousel-frame-border-width-default-narrow,
              'wide': $emu-component-containers-carousel-frame-border-width-default-wide
            ),
            'active': (
              'narrow': $emu-component-containers-carousel-frame-border-width-active-narrow,
              'wide': $emu-component-containers-carousel-frame-border-width-active-wide
            )
          )
        ),
        'color': (
          'background': (
            'light': $emu-component-containers-carousel-color-background-light,
            'dark': $emu-component-containers-carousel-color-background-dark
          ),
          'border': (
            'light': $emu-component-containers-carousel-color-border-light,
            'dark': $emu-component-containers-carousel-color-border-dark
          )
        )
      ),
      'tabs': (
        'gap': (
          'horizontal': (
            'narrow': $emu-component-containers-tabs-gap-horizontal-narrow,
            'wide': $emu-component-containers-tabs-gap-horizontal-wide
          ),
          'vertical': (
            'narrow': $emu-component-containers-tabs-gap-vertical-narrow,
            'wide': $emu-component-containers-tabs-gap-vertical-wide
          )
        ),
        'navigation': (
          'item': (
            'padding': (
              'top': (
                'narrow': $emu-component-containers-tabs-navigation-item-padding-top-narrow,
                'wide': $emu-component-containers-tabs-navigation-item-padding-top-wide
              ),
              'right': (
                'narrow': $emu-component-containers-tabs-navigation-item-padding-right-narrow,
                'wide': $emu-component-containers-tabs-navigation-item-padding-right-wide
              ),
              'bottom': (
                'narrow': $emu-component-containers-tabs-navigation-item-padding-bottom-narrow,
                'wide': $emu-component-containers-tabs-navigation-item-padding-bottom-wide
              ),
              'left': (
                'narrow': $emu-component-containers-tabs-navigation-item-padding-left-narrow,
                'wide': $emu-component-containers-tabs-navigation-item-padding-left-wide
              )
            ),
            'gap': (
              'horizontal': (
                'narrow': $emu-component-containers-tabs-navigation-item-gap-horizontal-narrow,
                'wide': $emu-component-containers-tabs-navigation-item-gap-horizontal-wide
              )
            ),
            'borderWidth': (
              'top': (
                'default': $emu-component-containers-tabs-navigation-item-border-width-top-default,
                'active': $emu-component-containers-tabs-navigation-item-border-width-top-active
              ),
              'right': (
                'default': $emu-component-containers-tabs-navigation-item-border-width-right-default,
                'active': $emu-component-containers-tabs-navigation-item-border-width-right-active
              ),
              'bottom': (
                'default': $emu-component-containers-tabs-navigation-item-border-width-bottom-default,
                'active': $emu-component-containers-tabs-navigation-item-border-width-bottom-active
              ),
              'left': (
                'default': $emu-component-containers-tabs-navigation-item-border-width-left-default,
                'active': $emu-component-containers-tabs-navigation-item-border-width-left-active
              )
            ),
            'color': (
              'border': (
                'default': (
                  'light': $emu-component-containers-tabs-navigation-item-color-border-default-light,
                  'dark': $emu-component-containers-tabs-navigation-item-color-border-default-dark
                ),
                'active': (
                  'light': $emu-component-containers-tabs-navigation-item-color-border-active-light,
                  'dark': $emu-component-containers-tabs-navigation-item-color-border-active-dark
                )
              ),
              'background': (
                'default': (
                  'light': $emu-component-containers-tabs-navigation-item-color-background-default-light,
                  'dark': $emu-component-containers-tabs-navigation-item-color-background-default-dark
                ),
                'active': (
                  'light': $emu-component-containers-tabs-navigation-item-color-background-active-light,
                  'dark': $emu-component-containers-tabs-navigation-item-color-background-active-dark
                )
              )
            ),
            'text': (
              'color': (
                'default': (
                  'light': $emu-component-containers-tabs-navigation-item-text-color-default-light,
                  'dark': $emu-component-containers-tabs-navigation-item-text-color-default-dark
                ),
                'active': (
                  'light': $emu-component-containers-tabs-navigation-item-text-color-active-light,
                  'dark': $emu-component-containers-tabs-navigation-item-text-color-active-dark
                )
              )
            )
          )
        ),
        'content': (
          'padding': (
            'top': (
              'narrow': $emu-component-containers-tabs-content-padding-top-narrow,
              'wide': $emu-component-containers-tabs-content-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-tabs-content-padding-right-narrow,
              'wide': $emu-component-containers-tabs-content-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-tabs-content-padding-bottom-narrow,
              'wide': $emu-component-containers-tabs-content-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-tabs-content-padding-left-narrow,
              'wide': $emu-component-containers-tabs-content-padding-left-wide
            )
          ),
          'gap': (
            'horizontal': (
              'narrow': $emu-component-containers-tabs-content-gap-horizontal-narrow,
              'wide': $emu-component-containers-tabs-content-gap-horizontal-wide
            ),
            'vertical': (
              'narrow': $emu-component-containers-tabs-content-gap-vertical-narrow,
              'wide': $emu-component-containers-tabs-content-gap-vertical-wide
            )
          ),
          'color': (
            'background': (
              'light': $emu-component-containers-tabs-content-color-background-light,
              'dark': $emu-component-containers-tabs-content-color-background-dark
            )
          )
        )
      ),
      'isi': (
        'header': (
          'padding': (
            'top': (
              'narrow': $emu-component-containers-isi-header-padding-top-narrow,
              'wide': $emu-component-containers-isi-header-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-isi-header-padding-right-narrow,
              'wide': $emu-component-containers-isi-header-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-isi-header-padding-bottom-narrow,
              'wide': $emu-component-containers-isi-header-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-isi-header-padding-left-narrow,
              'wide': $emu-component-containers-isi-header-padding-left-wide
            )
          ),
          'color': (
            'background': (
              'light': $emu-component-containers-isi-header-color-background-light
            )
          )
        ),
        'body': (
          'padding': (
            'top': (
              'narrow': $emu-component-containers-isi-body-padding-top-narrow,
              'wide': $emu-component-containers-isi-body-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-containers-isi-body-padding-right-narrow,
              'wide': $emu-component-containers-isi-body-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-containers-isi-body-padding-bottom-narrow,
              'wide': $emu-component-containers-isi-body-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-containers-isi-body-padding-left-narrow,
              'wide': $emu-component-containers-isi-body-padding-left-wide
            )
          )
        )
      )
    ),
    'cards': (
      'teaser': (
        'padding': (
          'narrow': $emu-component-cards-teaser-padding-narrow,
          'wide': $emu-component-cards-teaser-padding-wide
        ),
        'disclaimer': (
          'padding': (
            'narrow': $emu-component-cards-teaser-disclaimer-padding-narrow,
            'wide': $emu-component-cards-teaser-disclaimer-padding-wide
          )
        ),
        'description': (
          'padding': (
            'narrow': $emu-component-cards-teaser-description-padding-narrow,
            'wide': $emu-component-cards-teaser-description-padding-wide
          )
        ),
        'title': (
          'max-width': $emu-component-cards-teaser-title-max-width,
          'margin': (
            'narrow': $emu-component-cards-teaser-title-margin-narrow,
            'wide': $emu-component-cards-teaser-title-margin-wide
          )
        )
      )
    ),
    'layers': (
      'modal': (
        'scrim': (
          'color': (
            'background': $emu-component-layers-modal-scrim-color-background
          )
        ),
        'body': (
          'sizing': (
            'width': $emu-component-layers-modal-body-sizing-width
          ),
          'padding': (
            'top': (
              'narrow': $emu-component-layers-modal-body-padding-top-narrow,
              'wide': $emu-component-layers-modal-body-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-layers-modal-body-padding-right-narrow,
              'wide': $emu-component-layers-modal-body-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-layers-modal-body-padding-bottom-narrow,
              'wide': $emu-component-layers-modal-body-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-layers-modal-body-padding-left-narrow,
              'wide': $emu-component-layers-modal-body-padding-left-wide
            )
          ),
          'color': (
            'background': (
              'dark': $emu-component-layers-modal-body-color-background-dark
            )
          ),
          'borderWidth': (
            'all': $emu-component-layers-modal-body-border-width-all
          )
        ),
        'actions': (
          'close': (
            'padding': (
              'top': $emu-component-layers-modal-actions-close-padding-top,
              'right': $emu-component-layers-modal-actions-close-padding-right,
              'bottom': $emu-component-layers-modal-actions-close-padding-bottom,
              'left': $emu-component-layers-modal-actions-close-padding-left
            )
          )
        )
      ),
      'cookieConsentBanner': (
        'maxWidth': $emu-component-layers-cookie-consent-banner-max-width,
        'text': (
          'color': (
            'light': $emu-component-layers-cookie-consent-banner-text-color-light,
            'dark': $emu-component-layers-cookie-consent-banner-text-color-dark
          )
        ),
        'color': (
          'background': (
            'light': $emu-component-layers-cookie-consent-banner-color-background-light,
            'dark': $emu-component-layers-cookie-consent-banner-color-background-dark
          )
        ),
        'shadow': $emu-component-layers-cookie-consent-banner-shadow,
        'padding': (
          'top': (
            'narrow': $emu-component-layers-cookie-consent-banner-padding-top-narrow,
            'wide': $emu-component-layers-cookie-consent-banner-padding-top-wide
          ),
          'right': (
            'narrow': $emu-component-layers-cookie-consent-banner-padding-right-narrow,
            'wide': $emu-component-layers-cookie-consent-banner-padding-right-wide
          ),
          'bottom': (
            'narrow': $emu-component-layers-cookie-consent-banner-padding-bottom-narrow,
            'wide': $emu-component-layers-cookie-consent-banner-padding-bottom-wide
          ),
          'left': (
            'narrow': $emu-component-layers-cookie-consent-banner-padding-left-narrow,
            'wide': $emu-component-layers-cookie-consent-banner-padding-left-wide
          )
        ),
        'settingsButton': (
          'text': (
            'typography': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide,
              'large': $emu-component-layers-cookie-consent-banner-settings-button-text-typography-large
            ),
            'color': (
              'light': $emu-component-layers-cookie-consent-banner-settings-button-text-color-light,
              'dark': $emu-component-layers-cookie-consent-banner-settings-button-text-color-dark,
              'focus': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark
              ),
              'hover': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark
              ),
              'active': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark
              )
            )
          ),
          'padding': (
            'top': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide
            ),
            'right': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide
            )
          ),
          'margin': (
            'top': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide
            ),
            'right': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide
            ),
            'bottom': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide
            ),
            'left': (
              'narrow': $emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow,
              'wide': $emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide
            )
          ),
          'borderWidth': $emu-component-layers-cookie-consent-banner-settings-button-border-width,
          'outlineWidth': $emu-component-layers-cookie-consent-banner-settings-button-outline-width,
          'borderRadius': (
            'topLeft': $emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left,
            'topRight': $emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right,
            'bottomRight': $emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right,
            'bottomLeft': $emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left
          ),
          'color': (
            'border': (
              'light': $emu-component-layers-cookie-consent-banner-settings-button-color-border-light,
              'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-border-dark,
              'focus': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark
              ),
              'hover': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark
              ),
              'active': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark
              )
            ),
            'background': (
              'light': $emu-component-layers-cookie-consent-banner-settings-button-color-background-light,
              'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-background-dark,
              'focus': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark
              ),
              'hover': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark
              ),
              'active': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark
              )
            ),
            'outlineColor': (
              'light': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light,
              'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark,
              'focus': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark
              ),
              'hover': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark
              ),
              'active': (
                'light': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light,
                'dark': $emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark
              )
            )
          )
        )
      )
    ),
    'ingredients': (
      'imageMap': (
        'buttons': (
          'size': (
            'height': $emu-component-ingredients-image-map-buttons-size-height,
            'width': $emu-component-ingredients-image-map-buttons-size-width
          ),
          'color': (
            'background': (
              'light': $emu-component-ingredients-image-map-buttons-color-background-light
            )
          )
        )
      )
    )
  )
);
