#science {
  .science {
    // main teaser
    &__teaser {
      min-height: 756px;
    }

    // Rheology tab section styles
    &__rheology {
      // top container with title and split-text
      &-top-container {
        padding-top: 38px;
        padding-left: var(--emu-common-spacing-medium);
        padding-right: 10px;

        @include mq('large') {
          padding-top: 61px;
          padding-left: var(--emu-common-spacing-brand-medium);
        }
      }

      &-title {
        margin-bottom: 33px;
        padding-right: 50px;

        @include mq('large') {
          margin-bottom: 37px;
        }
      }

      &-subtitle {
        margin-bottom: 14px;
      }

      &-description {
        sup {
          top: 2px;
          letter-spacing: 0.2px;
        }

        ul {
          padding-left: 25px;
          padding-right: 40px;
          margin-top: var(--emu-common-spacing-none);
          list-style: none;

          @include mq('large') {
            padding-left: 23px;
          }

          li {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: 12px;
              left: -14px;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: currentColor;
            }

            & + li {
              margin-top: var(--emu-common-spacing-none);
            }
          }
        }
      }

      &-content {
        &-container {
          margin-bottom: 51px;

          @include mq('large') {
            display: flex;
            margin-bottom: 92px;
          }

          > .container {
            @include mq('large') {
              width: 50%;
            }

            &:first-child {
              @include mq('large') {
                width: calc(50% - 2px); // to match design more closely
                padding-right: var(--emu-common-spacing-large);
              }
            }
          }
        }

        &--left {
          margin-bottom: 40px;
        }

        &-disc {
          @include mq('large') {
            font-size: var(--emu-common-font-sizes-body-medium);
          }
        }
      }

      &-images-container {
        margin-top: 33px;

        // breakpoint needed to make the design look good
        @include mq('500px') {
          display: flex;
        }

        @include mq('large') {
          margin-top: 49px;
        }

        > .container {
          // breakpoint needed to make the design look good
          @include mq('500px') {
            width: 50%;
          }
        }

        img {
          width: 100%;
        }
      }

      &-intro-box {
        padding-top: 38px;
        padding-right: var(--emu-common-spacing-medium);
        padding-bottom: 58px;
        padding-left: var(--emu-common-spacing-medium);

        @include mq('large') {
          padding-top: 61px;
          padding-right: var(--emu-common-spacing-brand-medium);
          padding-bottom: 86px;
          padding-left: var(--emu-common-spacing-brand-medium);
        }

        .intro-box {
          &__title {
            margin-bottom: 18px;

            @include mq('large', 'max-width') {
              max-width: 393px; // to match figma
            }

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-xs);
            }

            sup {
              @include mq('large', 'max-width') {
                display: inline-block;
                letter-spacing: -1.5px;
              }
            }
          }

          &__txt {
            max-width: 500px;
          }
        }
      }

      // inner tabs component
      &-charts {
        .aaaem-tabs {
          &__tabpanel {
            margin-bottom: 120px;

            @include mq('large') {
              margin-bottom: 174px;
            }
          }

          &__tab {
            @include mq('large') {
              font-size: var(--emu-common-font-sizes-headline-medium);
              letter-spacing: -0.13px;
            }

            a {
              @include mq('large') {
                padding-bottom: 20px;
              }
            }
          }
        }

        &-tab {
          &-title {
            padding-top: 37px;
            padding-right: var(--emu-common-spacing-large);
            padding-bottom: 31px;
            padding-left: var(--emu-common-spacing-medium);

            @include mq('large', 'max-width') {
              font-size: var(--emu-common-font-sizes-body-regular);
              font-weight: var(--emu-common-font-weight-600);
              line-height: var(--emu-common-line-heights-narrow-xxxl);
              letter-spacing: 0.4px;
            }

            @include mq('large') {
              padding-top: 55px;
              padding-right: var(--emu-common-spacing-brand-medium);
              padding-bottom: 92px;
              padding-left: var(--emu-common-spacing-brand-medium);
            }
          }

          &-img {
            @include mq('large') {
              padding-left: var(--emu-common-spacing-brand-medium);
              padding-right: var(--emu-common-spacing-brand-medium);
            }

            img {
              margin-left: auto;
              margin-right: auto;

              @include mq('large') {
                width: 100%;
              }
            }
          }
        }
      }

      &-technology-intro {
        padding-top: 38px;
        padding-right: var(--emu-common-spacing-medium);
        padding-bottom: 25px;
        padding-left: var(--emu-common-spacing-medium);
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-black);

        @include mq('large') {
          padding-top: 61px;
          padding-bottom: 78px;
          padding-left: 58px;
        }
      }

      &-img-txt-grid {
        &--vycross {
          .img-txt-grid {
            &__text {
              @include mq('large', 'max-width') {
                font-size: var(--emu-common-font-sizes-body-small);

                p,
                li {
                  font-size: inherit;
                }
              }
            }
          }
        }

        &--hylacross {
          .img-txt-grid {
            &__title {
              sup {
                font-size: 100%;
                top: 10px;

                @include mq('large') {
                  top: 18px;
                }
              }
            }
          }
        }

        .img-txt-grid {
          &__section {
            &--content {
              padding-top: 38px;
              padding-right: var(--emu-common-spacing-medium);
              padding-bottom: var(--emu-common-spacing-small);
              padding-left: var(--emu-common-spacing-medium);

              @include mq('large') {
                padding-left: var(--emu-common-spacing-brand-medium);
                padding-top: 65px;
                padding-right: 71px;
              }
            }
          }

          &__title {
            margin-bottom: 18px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-medium);
            }
          }
        }
      }
    }

    // Syringe tab section styles
    &__syringe {
      &-teaser {
        position: relative;

        &-content {
          &-container {
            padding-top: 36px;
            padding-right: var(--emu-common-spacing-medium);
            padding-left: var(--emu-common-spacing-medium);

            @include mq('large') {
              padding-top: 61px;
              padding-right: var(--emu-common-spacing-brand-medium);
              padding-bottom: var(--emu-common-spacing-brand-xs);
              padding-left: var(--emu-common-spacing-brand-medium);
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              max-width: 660px;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
          }
        }

        &-title {
          margin-bottom: 26px;

          @include mq('large') {
            margin-bottom: 21px;
          }
        }

        &-description {
          margin-bottom: 22px;
        }

        &-features-container {
          margin-bottom: var(--emu-common-spacing-large);
        }

        &-feature {
          display: flex;
          align-items: center;
          margin-bottom: var(--emu-common-spacing-medium);

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-brand-xs);
          }

          &-img {
            margin-right: var(--emu-common-spacing-brand-xs);

            img {
              width: 60px; // as per figma

              @include mq('large') {
                width: 80px; // as per figma
              }
            }
          }

          &-txt {
            @include mq('large') {
              font-size: var(--emu-common-font-sizes-headline-medium);
            }

            p,
            li {
              @include mq('large') {
                font-size: inherit;
                letter-spacing: -0.7px;
              }
            }
          }
        }

        &-img-container {
          img {
            width: 100%;
          }
        }
      }
    }

    &__modal {
      &[data-component='modal'] .modal-content {
        margin-top: auto;
      }
    }
  }

  .references {
    padding-bottom: 58px;
  }
}
