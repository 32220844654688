.references {
  padding-bottom: 72px;

  @include mq('large') {
    padding-bottom: 57px;
  }

  &__txt {
    p + p,
    li + li {
      margin-top: 15px;
    }

    sup {
      font-size: 80%;
    }

    .emphasis {
      sup {
        font-size: 100%;
        top: 4px;
      }
    }
  }
}
