h1,
.u-typography--h1,
.u-typography--h1-mobile-lg {
  letter-spacing: -0.2px;

  @include mq('large') {
    letter-spacing: -1.5px;
  }
}

.u-typography--h1-mobile-lg {
  @include mq('large', 'max-width') {
    letter-spacing: -0.9px;
  }
}

h2,
.u-typography--h2,
.u-typography--h2-mobile-sm {
  letter-spacing: -0.9px;

  @include mq('large') {
    letter-spacing: -1.2px;
  }
}

.u-typography--h2-mobile-sm {
  @include mq('large', 'max-width') {
    letter-spacing: -0.2px;
  }
}

h3,
.u-typography--h3,
.u-typography--h3-mobile-sm {
  letter-spacing: -0.2px;

  @include mq('large') {
    letter-spacing: -0.9px;
  }
}

.u-typography--h3-mobile-sm {
  @include mq('large', 'max-width') {
    letter-spacing: -0.1px;
  }
}

h4,
.u-typography--h4 {
  letter-spacing: -0.1px;
}

h5,
.u-typography--h5 {
  letter-spacing: -0.1px;
}

.u-typography--intro-heading {
  letter-spacing: -0.1px;

  @include mq('large') {
    letter-spacing: -1.2px;
  }
}
