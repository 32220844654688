.intro-box {
  @include mq('large', 'max-width') {
    padding-top: 30px;
    padding-bottom: 48px;
  }

  @include mq('large') {
    padding-top: 51px;
  }

  &__title {
    max-width: 358px; // as per figma
    margin-bottom: var(--emu-common-spacing-brand-xs);

    @include mq('large') {
      max-width: 820px; // to match figma design
      margin-bottom: var(--emu-common-spacing-small);
    }
  }

  &__pretitle {
    margin-bottom: var(--emu-common-spacing-none);

    @include mq('large') {
      max-width: 574px; // as per figma
    }
  }
}
