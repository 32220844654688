[data-component='modal'] {
  &.interactive-map-modal {
    .brand-card {
      &__content {
        padding-bottom: var(--emu-common-spacing-brand-xs);

        @include mq('large') {
          padding-top: 19px;
          padding-left: 25px;
          padding-right: 23px;
        }
      }

      &__title {
        margin-bottom: 10px;

        @include mq('large') {
          margin-bottom: 17px;
        }
      }
    }
  }
}
