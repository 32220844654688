.pre-footer {
  .intro-box {
    padding-top: 38px;
    padding-bottom: var(--emu-common-spacing-brand-xs);

    @include mq('large') {
      padding-top: 58px;
      padding-bottom: 58px;
    }
  }

  .img-txt-grid {
    &--join-alle {
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);

      @include mq('large') {
        border-bottom: var(--emu-common-border-width-none);
      }

      .img-txt-grid__section {
        &--content {
          padding-bottom: 50px;
        }
      }
    }

    &__section {
      &--content {
        padding-bottom: 59px;

        @include mq('large') {
          padding-top: 65px;
        }
      }
    }

    &__title {
      @include mq('large') {
        max-width: 75%;
      }
    }

    &__text {
      max-width: 85%;

      @include mq('large') {
        max-width: none;
        margin-bottom: 25px;
      }
    }
  }
}
