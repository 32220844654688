.u-typography--intro-heading {
  font-size: var(--emu-common-font-sizes-narrow-large);
  line-height: var(--emu-common-line-heights-custom-regular);

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-headings-xxl);
  }
}

// ********************************************************************************** //
// ** START: Font Family Utilities
// ********************************************************************************** //
.u-font-family--serif-bold {
  font-family: var(--emu-common-font-families-serif-bold);
}

.u-font-family--serif-roman {
  font-family: var(--emu-common-font-families-serif-roman);
}

// ********************************************************************************** //
// ** END: Font Family Utilities
// ********************************************************************************** //

// ********************************************************************************** //
// ** Start: Text Color Utilities
// ********************************************************************************** //
[data-component='text'][class*='u-italic-tag-color--']:not(.has-italic),
[data-component='title'][class*='u-italic-tag-color--']:not(.has-italic) {
  i {
    font-style: normal; // needed to override any defaults added
  }
}

.u-italic-tag-color--ultra {
  i {
    color: var(--emu-common-colors-brand-ultra);
  }
}

.u-italic-tag-color--ultra-plus {
  i {
    color: var(--emu-common-colors-brand-ultra-plus);
  }
}

.u-italic-tag-color--voluma {
  i {
    color: var(--emu-common-colors-brand-voluma);
  }
}

.u-italic-tag-color--volbella {
  i {
    color: var(--emu-common-colors-brand-volbella);
  }
}

.u-italic-tag-color--volux {
  i {
    color: var(--emu-common-colors-brand-volux);
  }
}

.u-italic-tag-color--vollure {
  i {
    color: var(--emu-common-colors-brand-vollure);
  }
}
